import gql from "graphql-tag";
import store from "@/store";

// This function is supplied as the `update` property in the
// `$apollo.mutate()` call for backstock batch updates.
export const batchMutationUpdate = (cache, data) => {
  const {
    data: {
      batchUpdateBackstocks: { backstocks, errors },
    },
  } = data;

  // If there are any graphql errors, throw them.
  if (errors.length) {
    throw errors.join("; ");
  }

  // If the backstocks have been moved to a terminal location,
  // remove them from the cache.
  if (backstocks.some((backstock) => backstock.location.terminal)) {
    return removeTerminalBackstocks(cache, backstocks);
  }

  return true;
};

// Used for reducing a list of terminal backstocks
// to a list of cached backstocks without the terminal backstocks
// Intent: used as the cb to a `.reduce` call
const spliceBackstockFromList = (list, backstock) => {
  const idx = list.findIndex((cache) => cache.id === backstock.id);

  if (idx > -1) {
    list.splice(idx, 1);
  }
  return list;
};

// Find the given backstock in the given cacheBackstocks list.
// If found, return its bin. Otherwise, return undefined.
// Intent: used as the cb to a `.map` call
const cacheBackstockBin = (backstock, cacheBackstocks) => {
  backstock = cacheBackstocks.find((cache) => cache.id === backstock.id);
  return (backstock && backstock.bin) || undefined;
};

// Iterate `backstocks`, removing each from the cache
const removeTerminalBackstocks = (cache, backstocks) => {
  const variables = {
    storeId: store.getters["auth/activeStoreId"],
  };

  const backstockQuery = gql`
    query ($storeId: ID!) {
      backstocks(storeId: $storeId) {
        id
        bin {
          id
          name
          isFilled
        }
      }
    }
  `;
  // -- Remove the backstocks
  // read from the cache
  const { backstocks: cacheBackstocks = [] } = cache.readQuery({
    query: backstockQuery,
    variables,
  });

  if (!cacheBackstocks.length) return;

  // Find the index of each backstock and remove it from the
  // list of cached backstocks.
  const backstocksWithTerminalsRemoved = backstocks.reduce(
    spliceBackstockFromList,
    [...cacheBackstocks]
  );

  // If nothing changed, return
  if (backstocksWithTerminalsRemoved.length === cacheBackstocks.length) return;

  // Write the result back to the cache
  cache.writeQuery({
    query: backstockQuery,
    variables,
    data: { backstocks: backstocksWithTerminalsRemoved },
  });

  // -- Replace the bins
  // define the bins query
  const binQuery = gql`
    query ($storeId: ID!) {
      bins(storeId: $storeId, available: true) {
        id
      }
    }
  `;
  // read from the cache
  const { bins = [] } = cache.readQuery({
    query: binQuery,
    variables,
  });
  const oldBins = backstocks
    .map((backstock) => cacheBackstockBin(backstock, cacheBackstocks))
    .filter(Boolean);
  // write the bins back to the cache
  cache.writeQuery({
    query: binQuery,
    variables,
    data: { bins: [...bins, ...oldBins] },
  });
};
